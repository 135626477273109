
















import Vue from 'vue';

export default Vue.extend({
    name: 'selectField',
    model: {
        prop: 'model',
        event: 'change'
    },
    props: {
        options: {
            type: Object,
            required: true
        },
        model: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        }
    },
    computed: {
        val: {
            get(): string {
                return this.model;
            },
            set(v: string) {
                this.$emit('change', v);
            }
        }
    }
});
