export default {
    partnerTitle: 'Партнер',
    partner_title: 'Партнер',
    webmaster: 'Web-мастер',
    clicks: 'Клики',
    registrations: 'Регистрации',
    requests: 'Заявки',
    declines: 'Отказы',
    approves: 'Одобренные',
    issues: 'Подписки',
    profit: 'Расходы',
    cr: 'CR',
    ar: 'AR',
    epc: 'EPC',
    target_events: 'Целевое действие',
    campaign: 'Метка',

    project: 'Проект',
    postback: 'Постбэк',
    period: 'Период',
    bet: 'Ставка руб.',
    actions: 'Операции'
};
